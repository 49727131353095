<template>
    <button class="winex-btn" :class="classList" @click="onClick">{{content}}</button>
</template>

<script>
    export default {
        name: "DefaultButton",
        props: {
            classList: {
                default:"",
                type:String
            },
            content: {
                default:"",
                type: String

            },
            onClick: {
                default: () => {},
                type:Function
            }
        }
    }
</script>

<style scoped>

</style>
