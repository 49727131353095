<template>
    <div class="modal-wrap" v-if="isOpen">
        <div class="alert-modal-wrap" :style="{width:$isMobile()?'calc(100% - 20px)':pcWidth + 'px'}">
            <div v-if="!useCustom">
                <div class="modal-info-icon-wrap" v-if="isInfo">
                    <img class=" mx-auto" src="../../assets/images/icon/modal-info.png">
                </div>
                <div class="alert-modal-content">
                    <h2>{{title}}</h2>
                    <p v-html="content"></p>
                    <div v-if= "showWait == 'show'">
                        <div class="loader" >...</div>
                    </div>
                </div>
                <div class="alert-modal-footer">
                    <DefaultButton
                    :content="buttonContent"
                    :class-list="selfButtonClass"
                    :onClick="confirmClickBtn"
                    :style="mobileStyle"
                    ></DefaultButton>
                </div>
                <div v-if= "showWait == 'show'">
                    <p></p>
                    <p>* 완료시까지 화면을 갱신하지 마시고 기다려주세요.</p>
                </div>
            </div>
            <div v-if="useCustom">
                <slot name="custom">

                </slot>
            </div>
        </div>
    </div>
</template>

<script>
    import DefaultButton from "../buttons/DefaultButton";
    export default {
        name: "AlertModal",
        components: {DefaultButton},
        props: {
            useCustom: {
                default:false,
                type:Boolean
            },
            isInfo: {
                default:false,
                type:Boolean
            },
            title: {
                default:"타이틀",
                type:String
            },
            content: {
                default:"내용",
                type:String
            },
            showWait: {
                default:"hide",
                type:String
            },
            buttonContent: {
                default:"확인",
                type:String
            },
            buttonClass: {
                default:"",
                type:String
            },
            confirmClickBtn: {
                default:() => {},
                type:Function
            },
            pcWidth: {
                default:640
            },
            isOpen: {
                default:false,
                type:Boolean
            }
        },
        data() {
            return {
                selfButtonClass: this.buttonClass + " btn-secondary",
                mobileStyle:{}
            }
        },
        mounted() {
            if(this.$isMobile()) {
                this.selfButtonClass = this.selfButtonClass + " h48"
                this.mobileStyle = {width:'100%'}
            }
        }
    }
</script>

<style scoped>

</style>
