<template>
  <div>
    <div class="wrap">
      <div class="top-area">
        <div class="title">
          Create
        </div>
        <div class="step">
          <div class="txt">NFT 정보</div><div class="right-arrow"></div>
          <div class="txt">와인 정보</div><div class="right-arrow"></div>
          <div class="txt active">와인 설명</div>
        </div>
      </div>
      <div class="flex-row">
        <div class="form flex-align-start">
          <div class="image-area-t2">
            <div class="field-image-file">
              <div class="ic-plus"></div>
              <div class="title">
                등록하실 와인 NFT의 이미지를 등록해주세요.
              </div>
              <div class="subject">
                jpg, png의 파일만 업로드 가능합니다<br/>
                권장사이즈 : 600*600
              </div>
            </div>
          </div>
          <div class="info-area">
            <div class="brand-name">
              Baron Philippe de Rothschild
            </div>
            <div class="nft-name">
              [NFT] Domaine Comte Georges de Vogue Musigny Vieilles Vignes Grand Cru 2019
            </div>

            <!-- 와인 특성 정보 -->
            <div class="detail-wine-info-wrap">
              <ul class="detail-wine-info-list">

                <li class="detail-wine-info-item">
                  <span class="detail-wine-info-title">국가</span>
                  <span class="detail-wine-info-content">France</span>
                </li>
                <li class="detail-wine-info-item">
                  <span class="detail-wine-info-title">지역</span>
                  <span class="detail-wine-info-content">Bordeaux</span>
                </li>
                <li class="detail-wine-info-item">
                  <span class="detail-wine-info-title">source</span>
                  <span class="detail-wine-info-content">MERCHANT</span>
                </li>

                <li class="detail-wine-info-item">
                  <span class="detail-wine-info-title">빈티지</span>
                  <span class="detail-wine-info-content">2012</span>
                </li>
                <li class="detail-wine-info-item">
                  <span class="detail-wine-info-title">케이스</span>
                  <span class="detail-wine-info-content">12 Bottles</span>
                </li>
                <li class="detail-wine-info-item">
                  <span class="detail-wine-info-title">등급</span>
                  <span class="detail-wine-info-content">Saint-Estephe</span>
                </li>
                <li class="detail-wine-info-item">
                  <span class="detail-wine-info-title">종류</span>
                  <span class="detail-wine-info-content">RED</span>
                </li>
                <li class="detail-wine-info-item">
                  <span class="detail-wine-info-title">오리진</span>
                  <span class="detail-wine-info-content">SECONDARMAM</span>
                </li>

                <li class="detail-wine-info-item">
                  <span class="detail-wine-info-title">생산자</span>
                  <span class="detail-wine-info-content">Meyney</span>
                </li>
                <li class="detail-wine-info-item">
                  <span class="detail-wine-info-title">포맷</span>
                  <span class="detail-wine-info-content">75 CI</span>
                </li>


              </ul>
            </div>

            <div class="stock-tooltip">
              <div class="line">
                <div class="title">생성 수량</div>
                <div class="content">3</div>
              </div>
              <div class="line">
                <div class="title">판매 가격</div>
                <div class="content">
                  550USDC
                  <span>(3,000,000원)</span>
                </div>
              </div>
            </div>
            <dl>
              <dt>와인 설명</dt>
              <dd>
                <textarea placeholder="Description"></textarea>
              </dd>
            </dl>

            <div class="btn-row">
              <router-link :to="{path: `/users/minter/create/step2`}" class="prev-step-btn">
                이전
              </router-link>
              <div class="next-step-btn-2" @click="submitNftMinting">
                민팅 완료
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AlertModal
        :title="modal.title"
        :content="modal.content"
        :pcWidth="500"
        :button-content="'확인'"
        :button-class="'primary-btn'"
        :is-open="flagModalOnOff"
        :confirm-click-btn="closeModalAndNext">
    </AlertModal>

  </div>
</template>
<script>
import AlertModal from "@/components/modals/AlertModal";
export default {
  name: 'CreateNft',
  components: {
    AlertModal

  },
  created() {

  },
  data () {
    // 데이터 초기 값
    return  {
      flagModalOnOff:false,
      modal : {
          title: "NFT 생성 완료",
          content: "와인 NFT가 민팅 완료 되었습니다."
      }
    }
  },
  methods: {
    submitNftMinting(){
      //NFT 민팅 처리하기
      ////////////////
      ////////////////
      //민팅 정상 완료되면 모달 띄우기
      this.flagModalOnOff = true;
    },
    closeModalAndNext() {
      // 예외 분기 처리 필요 : ex. 민팅 실패 시, TIME OUT 시
      // ---
      // 모달 닫기
      this.flagModalOnOff = false;
      // 민팅 정상 완료 시 이동
      this.$router.push('/market/list');
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/create-nft.scss";
</style>